export const IntegrationKeys = {
  Integrations: 'Integrations',
  IntegrationsConnection: 'IntegrationsConnection',
  Zapier: 'Zapier',
  // Jira
  JiraProjects: 'JiraProjects',
  JiraUsers: 'JiraUsers',
  JiraIssues: 'JiraIssues',
  JiraIntegration: 'JiraIntegration',
  JiraIntegrationSave: 'JiraIntegrationSave',
  // Xero
  XeroSuppliers: 'XeroSuppliers',
  XeroAccounts: 'XeroAccounts',
  XeroItems: 'XeroItems',
  XeroOrganizations: 'XeroOrganizations',
  XeroTaxRates: 'XeroTaxRates',
  XeroTrackingCategory: 'XeroTrackingCategory',
  XeroIntegration: 'XeroIntegration',
  // Quickbooks
  QuickbooksVendors: 'QuickbooksVendors',
  QuickbooksCategories: 'QuickbooksCategories',
  QuickbooksCustomers: 'QuickbooksCustomers',
  QuickbooksItems: 'QuickbooksItems',
  QuickbooksOrganizations: 'QuickbooksOrganizations',
  QuickbooksTaxRates: 'QuickbooksTaxRates',
  QuickbooksIntegration: 'QuickbooksIntegration',
  // Approveit
  ApproveitVendors: 'ApproveitVendors',
  ApproveitTaxRates: 'ApproveitTaxRates',
  // SoftLedger
  SoftLedgerIntegration: 'SoftLedgerIntegration',
  SoftLedgerLocations: 'SoftLedgerLocations',
  SoftLedgerTaxes: 'SoftLedgerTaxes',
  SoftLedgerAccounts: 'SoftLedgerAccounts',
  SoftLedgerVendors: 'SoftLedgerVendors',
  SoftLedgerCurrencies: 'SoftLedgerCurrencies',
  SoftLedgerSettings: 'SoftLedgerSettings',
  SoftLedgerCustomDimension: 'SoftLedgerCustomDimension',
  SoftLedgerProducts: 'SoftLedgerProducts',
  SoftLedgerJobs: 'SoftLedgerJobs',
  SoftLedgerCostCenters: 'SoftLedgerCostCenters',
  // BambooHR
  BambooHRIntegration: 'BambooHRIntegration',
  BambooHRIntegrationSave: 'BambooHRIntegrationSave',
  // Google Calendar
  GoogleCalendar: 'GoogleCalendar',
  GoogleCalendarList: 'GoogleCalendarList',
  // ApproveitAI
  ApproveitAI: 'ApproveitAI',
  ApproveitAISave: 'ApproveitAISave',
}

export const WorkflowKeys = {
  Workflow: 'Workflow',
  Workflows: 'Workflows',
  Categories: 'Categories',
  Templates: 'Templates',
}

export const PipelinesKeys = {
  Pipelines: 'Pipelines',
  Processes: 'Processes',
  NextNumber: 'NextNumber',
}

export const TeamKeys = {
  Questions: 'Questions',
  User: 'User',
  Users: 'Users',
  AllUsers: 'AllUsers',
  AddUser: 'AddUser',
  AddTeam: 'AddTeam',
  InvitedUsers: 'InvitedUsers',
  InvitingUsers: 'InvitingUsers',
  UserRoles: 'UserRoles',
  ChangeUserRole: 'ChangeUserRole',
  Onboarding: 'Onboarding',
  OnboardingRequest: 'OnboardingRequest',
  SendRequest: 'SendRequest',
  Team: 'Team',
  API: 'API',
}

export const RequestsKeys = {
  Request: 'Request',
  PublicRequest: 'PublicRequest',
  RequestValidation: 'RequestValidation',
  Requests: 'Requests',
  Export: 'Export',
  Filters: 'Filters',
  ApproveReject: 'ApproveReject',
  CreateRequest: 'CreateRequest',
  RequestComments: 'RequestComments',
}

export const AccountKeys = {
  Me: 'Me',
  LogOut: 'LogOut',
  SlackToken: 'SlackToken',
  MyTeam: 'MyTeam',
  Teams: 'Teams',
  JoinTeamRequest: 'JoinTeamRequest',
  JoinTeamApprove: 'JoinTeamApprove',
  Password: 'Password',
  TwoFAList: 'TwoFAList',
  TwoFADelete: 'TwoFADelete',
  Activate: 'Activate',
  InviteToken: 'InviteToken',
  Settings: 'Settings',
}

export const FileKeys = {
  File: 'File',
}

export const DashboardKeys = {
  Dashboard: 'Dashboard',
}

export const BillingKeys = {
  Subscription: 'Subscription',
  UnsubscribeQuestion: 'UnsubscribeQuestion',
  CancelSubscription: 'CancelSubscription',
  StripeCheckout: 'StripeCheckout',
}

export const OrgStructureKeys = {
  Department: 'Department',
  TeamDepartments: 'TeamDepartments',
  OrgStructure: 'OrgStructure',
  OrgStructureDropdown: 'OrgStructureDropdown',
  OrgStructureDropdownById: 'OrgStructureDropdownById',
  OrgStructureIDS: 'OrgStructureIDS',
  Invites: 'Invites',
  Locations: 'Locations',
  Location: 'Location',
  Filters: 'Filters',
  Export: 'Export',
  SlackImport: 'SlackImport',
  MicrosoftImport: 'MicrosoftImport',
  BambooHRImport: 'BambooHRImport',
  TaskStatus: 'TaskStatus',
}

export const AccessKeys = {
  Resource: 'Resource',
  Resources: 'Resources',
  ResourcesList: 'ResourcesList',
  ResourcesByUserId: 'ResourcesByUserId',
  AccessUsers: 'AccessUsers',
  AccessUsersByResourceId: 'AccessUsersByResourceId',
  AccessExport: 'AccessExport',
  AccessImport: 'AccessImport',
}

export const SlackKeys = {
  SlackChannels: 'SlackChannels',
}

export const MailboxKeys = {
  Mailbox: 'Mailbox',
}

export const AccountingKeys = {
  Organizations: 'Organizations',
  Contracts: 'Contracts',
  ContractsReminder: 'ContractsReminder',
  ContractTypes: 'ContractTypes',
  VendorIntegrationSync: 'VendorIntegrationSync',
  VendorCsvImport: 'VendorCsvImport',
  Finance: 'Finance',
  VendorExport: 'VendorExport',
  ContractFilters: 'ContractFilters',
  Contacts: 'Contacts',
  // invoice
  Invoice: 'Invoice',
  InvoiceFilters: 'InvoiceFilters',
  // pos
  PurchaseOrder: 'PurchaseOrder',
  PurchaseOrderFilters: 'PurchaseOrderFilters',
  // quotes
  Quote: 'Quote',
  QuoteFilters: 'QuoteFilters',
  // accounts
  Accounts: 'Accounts',
  Taxes: 'Taxes',
  AccountCategories: 'AccountCategories',
  CostCenter: 'CostCenter',
  CostCenterBreakdownFilters: 'CostCenterBreakdownFilters',
}

export const FormKeys = {
  Form: 'Form',
}

export const TaskKeys = {
  Tasks: 'Tasks',
  Task: 'Task',
  Comments: 'TaskComments',
}

export const BoardKeys = {
  Boards: 'Boards',
  Board: 'Board',
  BoardTasks: 'BoardTasks',
}
export const ReportKeys = {
  Report: 'Report',
  Reports: 'Reports',
}

export const DWHKeys = {
  Reports: 'Reports',
}

export const loadableMutations = [
  RequestsKeys.ApproveReject,
  RequestsKeys.CreateRequest,
  TeamKeys.AddUser,
  TeamKeys.AddTeam,
  TeamKeys.SendRequest,
  TeamKeys.InvitingUsers,
  BillingKeys.StripeCheckout,
  BillingKeys.CancelSubscription,
  AccountKeys.MyTeam,
  AccountKeys.Password,
  AccountKeys.LogOut,
  AccountKeys.Activate,
  IntegrationKeys.JiraIntegrationSave,
  OrgStructureKeys.SlackImport,
  TeamKeys.API,
  AccountingKeys.VendorIntegrationSync,
  AccountingKeys.VendorCsvImport,
]
export const loadableQueries = [
  AccountKeys.Me,
  WorkflowKeys.Workflows,
  WorkflowKeys.Workflow,
  TeamKeys.Onboarding,
  FileKeys.File,
  DashboardKeys.Dashboard,
  RequestsKeys.Request,
  RequestsKeys.Requests,
  BillingKeys.Subscription,
  IntegrationKeys.JiraIntegration,
  RequestsKeys.RequestValidation,
  OrgStructureKeys.OrgStructure,
  MailboxKeys.Mailbox,
  AccountingKeys.Finance,
  AccountingKeys.Organizations,
  AccountingKeys.Invoice,
  AccountingKeys.Contracts,
  AccountingKeys.AccountCategories,
  AccountingKeys.Contacts,
  IntegrationKeys.XeroIntegration,
  PipelinesKeys.Pipelines,
  PipelinesKeys.Processes,
  FormKeys.Form,
  TaskKeys.Tasks,
  TaskKeys.Task,
  AccountingKeys.CostCenter,
  BoardKeys.BoardTasks,
  ReportKeys.Reports,
  ReportKeys.Report,
  BoardKeys.Boards,
  BoardKeys.Board,
]
