export default {
  public: {
    login: '/login',
    signup: '/signup',
    activate: '/activate',
    forgotPassword: '/reset-password',
    apiDoc: '/api-doc',
    approvalRequest: '/onboarding/approval-request',
    approvalRequestApproveReject: '/onboarding/approval-requests/:id/:status/:uuid',
    approvalRequestPublic: '/p/approval-request',
    approvalRequestApproveRejectPublic: '/p/approval-requests/:uuid/:status',
    quickbooksDisconnected: '/quickbooksintegrationdisconnected',
    publicForm: '/p/form/:uuid',
    notFound: '/404',
    installTeams: '/p/install-teams',
  },
  private: {
    '': '/',
    dashboard: '/dashboard',
    billing: '/billing',
    paywall: '/paywall/:feature',

    // settings
    settings: '/settings',
    settingsRouted: '/settings/:tab',

    // onboarding
    onboarding: '/onboarding',
    onboardingRequest: '/onboarding/request/:workflowId',
    onboardingWorkflow: '/onboarding/workflow/:id',

    // team
    organizationalStructure: '/members',
    organizationalStructureById: '/members/:id',
    teamDepartments: '/departments',
    teamDepartmentById: '/departments/:id',
    teamLocations: '/locations',
    teamLocationById: '/locations/:id',

    // mailbox
    mailbox: '/mailbox',

    // integration
    integrationsJira: '/integrations/jira',
    integrationsZapier: '/integrations/zapier',
    integrations: 'integrations',
    quickbooksConnect: '/quickbooksconnection',

    // pipelines
    pipelines: '/workflows',
    pipeline: '/workflow',
    processes: '/processes',
    pipelineProcesses: '/workflow/:id/processes',
    pipelineProcessesById: '/workflow/processes/:id',
    pipelineById: '/workflow/:id',
    pipelineStageById: '/workflow/:id/:stageId',
    pipelineApprovalRequest: '/workflow/:pipelineId/approval-request',
    pipelineProcessApprovalRequest: '/process/:processId/stage/:stageId/approval-request',
    pipelineAccountingRequest: '/workflow/:pipelineId/accounting-form',
    pipelineProcessAccountingRequest: '/process/:processId/stage/:stageId/accounting-form',

    // approval
    approvalRequest: '/approval-request',
    approvalRequestById: '/approval-request/:workflowId',
    approvalRequestEditById: '/approval-request/edit/:requestId',
    approvalRequests: '/approval-requests',
    approvalRequestsOnboarding: '/approval-requests/onboarding',
    approvalRequestsById: '/approval-requests/:id',
    approvalRequestsByIdAction: '/approval-requests/:id/:action',
    approvalRequestsByIdUuidAction: '/approval-requests/:id/:action/:uuid',

    // access
    accesses: '/accesses',
    accessesRequests: '/accesses/requests',
    accessResources: '/access-resources',
    accessResourcesByUser: '/access-resources/user/:userId',
    accessResourcesItem: '/access-resources/item',
    accessResourcesItemById: '/access-resources/item/:id',
    accessUsers: '/access-resources-users',
    accessUsersItem: '/access-resources-users/item',
    accessUsersByResource: '/access-resources-users/:resourceId',
    accessUsersByResourceItem: '/access-resources-users/:resourceId/item',

    // contacts

    contacts: '/contacts/:contactParty',
    contactsList: '/contacts/:contactParty',
    contact: '/contacts/:contactParty/:id',
    contactContracts: '/contacts/:contactParty/:id/contracts',
    contactContract: '/contacts/:contactParty/:contactId/contracts/:id',

    // org

    organizations: '/organizations',
    organization: '/organizations/:id',

    // contracts
    contracts: '/contracts',

    // invoices
    invoices: '/invoices',
    invoiceById: '/invoices/:id',
    bills: '/bills',
    billById: '/bills/:id',

    // pos
    purchaseOrders: '/purchase-orders',
    purchaseOrdersById: '/purchase-orders/:id',

    // quotes
    quotes: '/quotes',
    quoteById: '/quotes/:id',

    // finance settings
    financeSettings: '/finance-settings',
    accounts: '/finance-settings/accounts',
    accountById: '/finance-settings/accounts/:id',
    accountCategories: '/categories',
    accountCategoryById: '/categories/:id',
    companyDetails: '/finance-settings/details',
    taxes: '/finance-settings/taxes',
    taxById: '/finance-settings/taxes/:id',

    // cost center
    costCenter: '/cost-center',
    costCenterNew: '/cost-center/new',
    costCenterById: '/cost-center/:id',
    costCenterEditById: '/cost-center/:id/edit',
    costCenterBudget: '/cost-center/:id/budget',
    costCenterBreakdown: '/cost-center/:id/breakdown',

    // file
    file: '/files/:id',

    // free requestor
    free: '/free',

    // test component route
    componentTest: '/test-component',

    // teams installation guide
    installTeams: '/install-teams',

    // tasks
    tasks: '/tasks',
    taskBoards: '/task-boards/:boardId',

    // reports
    reports: '/reports',
    aiReports: '/reports/ai',
    createReport: '/report',
    reportById: '/reports/:id',
  },
}
